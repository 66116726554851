import {ContactOption, loadStripe, StripeError} from "@stripe/stripe-js";

interface PaymentAmountUpdated {
  items: number[];
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;
}

interface ElementsOptions {
  mode: "payment";
  amount: number;
  currency: string;
  setupFutureUsage: "on_session";
  paymentMethodCreation: "manual";
  customerSessionClientSecret: string;
  appearance: {};
}

interface AddressOptions {
  mode: "shipping" | "billing";
  allowedCountries?: string[];
  fields?: { phone: "always" };
  validation?: { phone: { required: "always" } };
  contacts?: ContactOption[];
}

interface StripeAddress {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}


export async function initializeCheckout() {
  const stripe = await loadStripe(window.ENV.STRIPE_PUBLIC_KEY);

  if (!stripe) {
    console.error("Stripe wasn't loaded!");
    return;
  }

  const csrfToken: string = window.getCookie('csrftoken');
  // elements options are figured out dynamically on the server
  const optionsResponse = await fetch("/create-checkout-options", {
    method: "POST",
    headers: {"Content-Type": "application/json", 'X-CSRFToken': csrfToken}
  });
  const options: {
    elements_options: ElementsOptions, address_options: AddressOptions, return_url: string;
  } = await optionsResponse.json();

  // Create elements
  const elements = stripe.elements(options.elements_options);
  // Create and mount the Payment Element
  const paymentElement = elements.create('payment');
  paymentElement.mount('#payment-element');
  // Create and mount the Address Element
  const addressElement = elements.create("address", options.address_options);
  addressElement.mount("#address-element");

  // Listen on address change to update tax value
  addressElement.on('change', (event) => {
    if (event.complete) {
      // Extract potentially complete address
      const address = event.value.address;
      calculateTax(address);
    } else if (event.isNewAddress) {
      window.htmx.trigger("#checkout-costs", 'checkoutCostUpdated', {tax: null});
    }
  });

  const form = document.getElementById('payment-form');
  const submitBtn = document.getElementById('submit') as HTMLButtonElement;
  if (!form || !submitBtn) {
    console.log("Form or Submit button not found");
    return;
  }

  const handleServerResponse = async (
    response: { error?: StripeError, status: string, client_secret: string }
  ) => {
    if (response.error) {
      // Show error from server on payment form
      handleError(response.error);
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const {
        error,
        paymentIntent
      } = await stripe.handleNextAction({
        clientSecret: response.client_secret
      });

      if (error) {
        // Show error from Stripe.js in payment form
        handleError(error);
      } else {
        // Actions handled, show success message
        window.htmx.trigger("#checkout-container", 'checkoutCompleted', {});
      }
    } else {
      // No actions needed, show success message
      window.htmx.trigger("#checkout-container", 'checkoutCompleted', {});
    }
  };

  const handleError = (error: { message: string | undefined } | StripeError) => {
    const messageContainer = document.querySelector('#error-message');
    if (messageContainer) {
      messageContainer.textContent = error.message || "";
    }
    submitBtn.disabled = false;
  };

  form.addEventListener('submit', async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      // Prevent multiple form submissions
      if (submitBtn.disabled) {
        return;
      }

      // Disable form submission while loading
      submitBtn.disabled = true;

      // Trigger form validation and wallet collection
      const {error: submitError} = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      // Create the ConfirmationToken using the details collected by the Payment Element
      // and additional shipping information
      const {error, confirmationToken} = await stripe.createConfirmationToken({
        elements, params: {return_url: options.return_url}
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
        handleError(error);
        return;
      }

      try {
        // Create the PaymentIntent
        const res = await fetch("/create-intent", {
          method: "POST",
          headers: {"Content-Type": "application/json", 'X-CSRFToken': csrfToken},
          body: JSON.stringify({
            confirmationTokenId: confirmationToken.id,
          }),
        });
        const data: { error?: StripeError, status: string, client_secret: string } = await res.json();
        // Handle any next actions or errors. See the Handle any next actions step for implementation.
        await handleServerResponse(data);
      } catch (error) {
        console.error(error);
        handleError({message: `Unexpected error: ${error}`});
      }
    }
  );

  async function calculateTax(address: StripeAddress) {
    const res = await fetch("/calculate-tax", {
      method: "POST",
      headers: {"Content-Type": "application/json", 'X-CSRFToken': csrfToken},
      body: JSON.stringify({address}),
    });
    const data: PaymentAmountUpdated = await res.json();
    elements.update({amount: data.total});
    window.htmx.trigger("#checkout-costs", 'checkoutCostUpdated', data);
  }

  document.addEventListener("paymentAmountUpdated", async (event) => {
    const customEvent = event as CustomEvent<PaymentAmountUpdated>;
    elements.update({amount: customEvent.detail.total});
    if (customEvent.detail.tax === null) {
      const address = await elements.getElement("address")?.getValue();
      if (address && address.complete) {
        calculateTax(address.value.address);
      }
    }
  });

}
