import Cookies from "js-cookie";
import htmx from "htmx.org/dist/htmx.esm";

import {initializeCheckout} from "../components/checkout";
import {sendEvent} from "../components/event";
import {initTheme} from "../components/theme";

function hideElement(elementId: string) {
  let el = document.getElementById(elementId);
  if (el) {
    el.style.display = "none";
    el.dispatchEvent(new CustomEvent("elementClosed", {}));
  }
}

function showLoginModal() {
  let modal = document.getElementById("login-modal");
  if (modal) {
    modal.style.display = "block";
  }
}

window.showLoginModal = showLoginModal;
window.hideElement = hideElement;
window.getCookie = Cookies.get;
window.initializeCheckout = initializeCheckout;
window.sendEvent = sendEvent;

window.htmx = htmx;
window.addEventListener('load', initTheme);

