function setAutoTheme() {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('data-theme', 'dark');
  } else {
    document.documentElement.setAttribute('data-theme', 'light');
  }
}

export function initTheme() {
  const toggleSwitches = Array.from(document.getElementsByClassName('theme-switch')) as HTMLElement[];
  if (toggleSwitches.length === 0) {
    console.warn("Dark mode toggle not initialized. Didn't find `.theme-switch` elements.")
    return;
  }
  const currentTheme = localStorage.getItem('theme') ?? 'auto';
  if (currentTheme === "auto") {
    const element = document.querySelector('.theme-switch[data-theme="auto"]');
    element?.classList.toggle("active");
    setAutoTheme();
  } else {
    const element = document.querySelector(`.theme-switch[data-theme="${currentTheme}"]`);
    element?.classList.toggle("active");
    document.documentElement.setAttribute('data-theme', currentTheme);
  }

  function switchTheme(event: Event) {
    const target = event.target as HTMLElement;
    const switcher = target.closest(".theme-switch") as HTMLElement;
    const index = toggleSwitches.indexOf(switcher);
    switcher.classList.toggle("active");
    const nextSwitch = toggleSwitches[(index + 1) % 3];
    nextSwitch.classList.toggle("active");
    switch (nextSwitch.dataset.theme) {
      case "dark":
        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem('theme', 'dark');
        return;
      case "light":
        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem('theme', 'light');
        return;
      case "auto":
        setAutoTheme();
        localStorage.setItem('theme', 'auto');
        return;
    }
  }

  toggleSwitches.forEach(function (element) {
    element.addEventListener('click', switchTheme, false);
  });
}
